.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .loader-icon {
        position: fixed;
        width: 100px;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        color: white;
    }
}