@import "~bootstrap/scss/bootstrap";

body,
html {
    background-color: black;
    font-family: "Lato", sans-serif;
    color: #4d4d4d;
    font-size: 14px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.loader-wrapper {
    margin-left: auto;
    margin-right: auto;
}

.text-green {
    color: #b6cc33;
}
