html,
body {
    background-color: #ffc928;
    height: 100%;
}

.player-waiting,
.streaming-player {
    width: 100vw;
    max-width: 100%;
    min-width: 360px;
    @media (min-width: 992px) {
        width: 75vw;
    }
}

#header {
    background-image: url(https://aog-conf.b-cdn.net/bg-header-desktop.webp);
    background-size: cover;
    .relative {
        position: relative;
        .logo {
            width: 100%;
            max-width: 240px;
        }
        .logo-left {
            position: absolute;
            width: 48px;
            top: 1rem;
            left: 1rem;
        }
        @media (min-width: 992px) {
            margin-left: auto;
            margin-right: auto;
            .logo-left {
                width: 64px;
            }
            .logo {
                width: 100%;
                max-width: 360px;
            }
        }
    }
}
.tile-1x {
    background-image: url(https://aog-conf.b-cdn.net/tile-1x.webp);
    height: 16px;
    background-size: contain;
}
.tile-2x {
    background-image: url(https://aog-conf.b-cdn.net/tile-2x.webp);
    height: 32px;
    background-size: contain;
}
.streaming-container {
    position: relative;
    height: 100%;
    min-width: 360px;
    padding-bottom: 3rem;
}
.footer {
    left: 0;
    right: 0;
    bottom: 0;
}

#playerContainer {
    display: inline-block;
    position: relative;
    margin-bottom: 1rem;
    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}

#playerContainer {
    max-width: 1024px;
}
.chat-wrapper {
    @media (min-width: 992px) {
        margin-top: 0;
        margin-left: 1.5rem;
    }
    .chat-change-lang {
        text-align: left;
        margin-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        @media (min-width: 992px) {
            margin-top: 0;
            margin-left: 0;
        }
    }
    #chat_box {
        height: 50vh;
        @media (min-width: 992px) {
            height: 70vh;
        }
    }
}
